import deepParseJson from 'utils/deepParseJson'
import ApiService from './ApiService'
import { PERSIST_STORE_NAME } from 'constants/app.constant'

const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
const persistData = deepParseJson(rawPersistData)
const level = persistData.auth.user.authority[0]

export async function apiGetWarranties(limit = 10, page = 1, status) {
    return ApiService.fetchData({
        url: `/warranties?limit=${limit}&page=${page}${status ? `${status}` : ''}`,
        method: 'get',
    })
}

export async function apiGetFilteredWarranties({ limit = 10, page = 1, status, dealer_id = null, tag = null, start_date = null, end_date = null }) {
    // Create query parameters object
    const queryParams = new URLSearchParams({
        limit,
        page,
        ...(dealer_id && { dealer_id }),
        ...(tag && { tag }),
        ...(start_date && { start_date }),
        ...(end_date && { end_date })
    });

    return ApiService.fetchData({
        url: `/warranties?${queryParams.toString()}${status ? `${status}` : ''}`,
        method: 'get',
    });
}


export async function apiGetSingleWarranty(id) {
    return ApiService.fetchData({
        url: `/warranties/${id}`,
        method: 'get',
    })
}

export async function apiCreateVehicle(data) {
    if (level === 'admin') {
        const dealerId = JSON.parse(localStorage.getItem('activeDealer')).value
        data.dealer_id = dealerId
    }

    return ApiService.fetchData({
        url: `/vehicles`,
        method: 'post',
        data
    })

}

export async function apiCreateCustomer(data) {
    return ApiService.fetchData({
        url: `/customers`,
        method: 'post',
        data
    })
}

export async function apiCreateWarranty(data) {
    return ApiService.fetchData({
        url: `/warranties`,
        method: 'post',
        data
    })
}

export async function apiGetProducts({ milage, vehicle_id, valid_from, vehicle_value }) {
    const queryParams = new URLSearchParams({
        milage,
        vehicle_id,
        valid_from,
        vehicle_value
    })

    if (level === 'admin') {
        const dealerId = JSON.parse(localStorage.getItem('activeDealer')).value

        queryParams.append('dealer_id', dealerId)
    }


    return ApiService.fetchData({
        // url: `/products_available?milage=${milage}&vehicle_id=${vehicle_id}&valid_from=${valid_from}&vehicle_value=${vehicle_value}`,
        url: `/products_available?${queryParams.toString()}`,
        method: 'get',
    })
}

export async function apiGetProductById(id) {
    return ApiService.fetchData({
        url: `/products/${id}`,
        method: 'get',
    })
}

export async function apiAcceptWarranty(data) {
    return ApiService.fetchData({
        url: `/warranties_accepted`,
        method: 'post',
        data
    })
}

export async function apiTerminateWarranty(data) {
    return ApiService.fetchData({
        url: `/warranties_cancelled`,
        method: 'post',
        data
    })
}
export async function apiRefundWarranty(data) {
    return ApiService.fetchData({
        url: `/warranties_refunded`,
        method: 'post',
        data
    })
}

export async function apiWarrantyPayment(warranty_id) {
    return ApiService.fetchData({
        url: `/warranties/${warranty_id}/payment_links`,
        method: 'post',
    })
}
export async function apiWarrantyPaymentIntent(warranty_id, saved_card_id = null) {
    return ApiService.fetchData({
        url: `/warranties/${warranty_id}/payment_intents`,
        method: 'post',
        data: {
            "setup_future_usage": true,
            // only add this if saved_card_id is not null
            ...(saved_card_id && { "payment_method": saved_card_id })
        }
    })
}

export async function apiSearch(query) {
    return ApiService.fetchData({
        url: `/search?query=${query}`,
        method: 'get',
    })
}

export async function apiGetCustomer(id) {
    return ApiService.fetchData({
        url: `/customers/${id}`,
        method: 'get',
    })
}

export async function apiGetAllCustomers(limit = 10, page = 1) {
    let url = `/customers?limit=${limit}&page=${page}`
    if (level === 'admin') {
        const dealerId = JSON.parse(localStorage.getItem('activeDealer')).value
        url = `/customers?limit=${limit}&page=${page}${dealerId ? `&dealer_id=${dealerId}` : ''}`
    }
    return ApiService.fetchData({
        url,
        method: 'get',
    })
}

export async function apiCreateClaim(data) {
    return ApiService.fetchData({
        url: `/claims`,
        method: 'post',
        data
    })
}

export async function apiUpdateClaim(id, data) {
    return ApiService.fetchData({
        url: `/claims/${id}`,
        method: 'put',
        data
    })
}

export async function apiGetClaims(limit = 10, page = 1, status = null) {
    return ApiService.fetchData({
        url: `/claims?limit=${limit}&page=${page}${status ? `${status}` : ''}`,
        method: 'get',
    })
}

export async function apiGetFilteredClaims({
    limit = 10,
    page = 1,
    status = null,
    dealer_id = null,
    start_date = null,
    end_date = null
}) {
    const queryParams = new URLSearchParams({
        limit,
        page,
        ...(dealer_id && { dealer_id }),
        ...(start_date && { start_date }),
        ...(end_date && { end_date })
    })

    return ApiService.fetchData({
        url: `/claims?${queryParams.toString()}${status ? `${status}` : ''}`,
        method: 'get',
    })
}


export async function apiGetWarrantiesForUser(id, limit = 10, page = 1, paidOnly = true) {
    let url = `/warranties?customer_id=${id}&limit=${limit}&page=${page}`
    if (level === 'admin') {
        const dealerId = JSON.parse(localStorage.getItem('activeDealer')).value
        url = `/warranties?customer_id=${id}&limit=${limit}&page=${page}${dealerId ? `&dealer_id=${dealerId}` : ''}${paidOnly ? '&paid=true' : ''}`
    }

    return ApiService.fetchData({
        url,
        method: 'get',
    })
}

export async function apiGetClaimsForUser(id, limit = 10, page = 1) {
    let url = `/claims?customer_id=${id}&limit=${limit}&page=${page}`
    if (level === 'admin') {
        const dealerId = JSON.parse(localStorage.getItem('activeDealer')).value
        url = `/claims?customer_id=${id}&limit=${limit}&page=${page}${dealerId ? `&dealer_id=${dealerId}` : ''}`
    }

    return ApiService.fetchData({
        url,
        method: 'get',
    })
}


export async function apiCreateClaimItem(id, data) {
    return ApiService.fetchData({
        url: `/claims/${id}/line_items`,
        method: 'post',
        data
    })
}

export async function apiCreateClaimParts(id, data) {
    return ApiService.fetchData({
        url: `/claims/${id}/parts`,
        method: 'post',
        data
    })
}

export async function apiGetClaim(id) {
    return ApiService.fetchData({
        url: `/claims/${id}`,
        method: 'get',
    })
}

// Claim actions
export async function apiApproveClaim(id) {
    return ApiService.fetchData({
        url: `/claims_approved`,
        data: {
            id
        },
        method: 'post',
    })
}

export async function apiRejectClaim(id) {
    return ApiService.fetchData({
        url: `/claims_rejected`,
        data: {
            id
        },
        method: 'post',
    })
}

export async function apiFinalizeClaim(id) {
    return ApiService.fetchData({
        url: `/claims_finalised`,
        data: {
            id
        },
        method: 'post',
    })
}

export async function apiPayClaim(id) {
    return ApiService.fetchData({
        url: `/claims_paid`,
        data: {
            id
        },
        method: 'post',
    })
}

export async function apiVoidClaim(id) {
    return ApiService.fetchData({
        url: `/claims_void`,
        data: {
            id
        },
        method: 'post',
    })
}
// End of claim actions

export async function apiGetPreSignedUrl(id) {
    return ApiService.fetchData({
        url: `/claims/${id}/attachment_upload_urls`,
        method: 'post',
    })
}

export async function apiUploadAttachment(url, file) {
    return ApiService.uploadFile(url, file)
}

export async function apiAttachImageToClaim(id, data) {
    return ApiService.fetchData({
        url: `/claims/${id}/attachments`,
        method: 'post',
        data
    })
}

export async function apiDeleteAttachment(claim_id, attachment_id) {
    return ApiService.fetchData({
        url: `/claims/${claim_id}/attachments/${attachment_id}`,
        method: 'delete',
    })
}

export async function apiRemoveClaimItems(claim_id, item_id) {
    return ApiService.fetchData({
        url: `/claims/${claim_id}/line_items/${item_id}`,
        method: 'delete',
    })
}

export async function apiRemoveClaimParts(claim_id, part_id) {
    return ApiService.fetchData({
        url: `/claims/${claim_id}/parts/${part_id}`,
        method: 'delete',
    })
}

export async function apiGetWarrantyStats(from, to) {
    return ApiService.fetchData({
        url: `stats/warranties?start_date=${from}&end_date=${to}`,
        method: 'get',
    })
}

export async function apiDownloadWarranty(id) {
    return ApiService.fetchData({
        url: `/warranties/${id}/download_links`,
        method: 'post',
    })
}

export async function apiUpdateUser(id, data) {
    return ApiService.fetchData({
        url: `/customers/${id}`,
        method: 'put',
        data
    })
}

export async function apiGetAllProducts(id, limit = 10, page = 1) {
    return ApiService.fetchData({
        url: `/products?limit=${limit}&page=${page}${id ? `&dealer_id=${id}` : ''}`,
        method: 'get',
    })
}

export async function apiGetProductBalance(id) {
    return ApiService.fetchData({
        url: `/products/${id}/balances`,
        method: 'get',
    })
}

export async function apiDownloadWarrantyInvoice(id) {
    return ApiService.fetchData({
        url: `/warranties/${id}/invoice_download_links`,
        method: 'post',
    })
}