import React, { forwardRef, useMemo, useRef, useEffect, useState, useImperativeHandle } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Table, Pagination, Select, Checkbox, Alert } from 'components/ui'
import TableRowSkeleton from './loaders/TableRowSkeleton'
import Loading from './Loading'
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    flexRender,
} from '@tanstack/react-table'
import { mkConfig, generateCsv, download } from 'export-to-csv'
import { formatDate } from '@fullcalendar/react'
import { warrantyStatus } from 'views/warranties/WarrantyActions/helpers/actions'
import { formatCurrency } from 'utils/helpers'
import { BiDownload } from 'react-icons/bi'
import { HiOutlineClipboardList } from 'react-icons/hi'

const { Tr, Th, Td, THead, TBody, Sorter } = Table

const IndeterminateCheckbox = ((props) => {

    const { indeterminate, onChange, onCheckBoxChange, onIndeterminateCheckBoxChange, ...rest } = props

    const ref = useRef(null)

    useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, indeterminate])

    const handleChange = (e) => {
        onChange(e)
        onCheckBoxChange?.(e)
        onIndeterminateCheckBoxChange?.(e)
    }

    return <Checkbox className="mb-0" ref={ref} onChange={(_, e) => handleChange(e)} {...rest} />
})

const CSV_CONFIG_DEFAULTS = {
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
    quoteStrings: false,
}

const DataTable = forwardRef((props, ref) => {
    const {
        skeletonAvatarColumns,
        columns: columnsProp,
        data,
        loading,
        onCheckBoxChange,
        onIndeterminateCheckBoxChange,
        onPaginationChange,
        onSelectChange,
        onSort,
        pageSizes,
        selectable,
        skeletonAvatarProps,
        pagingData,
        paginate,
        highLightLastRow,
        getCSVRawData,
        renderFooterActions,
        showPagination,
        renderFooterText,
    } = props

    const { pageSize, pageIndex, total } = pagingData

    const [sorting, setSorting] = useState(null)

    const pageSizeOption = useMemo(
        () =>
            pageSizes.map((number) => ({
                value: number,
                label: `${number} / page`,
            })),
        [pageSizes]
    )

    const handleCheckBoxChange = (checked, row) => {
        if (!loading) {
            onCheckBoxChange?.(checked, row)
        }
    }

    const handleIndeterminateCheckBoxChange = (checked, rows) => {
        if (!loading) {
            onIndeterminateCheckBoxChange?.(checked, rows)
        }
    }

    const handlePaginationChange = (page) => {
        if (!loading) {
            onPaginationChange?.(page)
        }
    }

    const handleSelectChange = (value) => {
        if (!loading) {
            onSelectChange?.(Number(value))
        }
    }

    useEffect(() => {
        if (Array.isArray(sorting)) {
            const sortOrder = sorting.length > 0 ? sorting[0].desc ? 'desc' : 'asc' : ''
            const id = sorting.length > 0 ? sorting[0].id : ''
            onSort?.({ order: sortOrder, key: id })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sorting])

    const hasOldColumnMetaKey = columnsProp.some(col => col.Header || col.accessor || col.Cell)

    const finalColumns = useMemo(() => {

        const columns = columnsProp

        if (selectable) {
            return [
                {
                    id: 'select',
                    header: ({ table }) => (
                        <IndeterminateCheckbox
                            checked={table.getIsAllRowsSelected()}
                            indeterminate={table.getIsSomeRowsSelected()}
                            onChange={table.getToggleAllRowsSelectedHandler()}
                            onIndeterminateCheckBoxChange={(e) => {
                                handleIndeterminateCheckBoxChange(
                                    e.target.checked,
                                    table.getRowModel().rows
                                )
                            }}
                        />
                    ),
                    cell: ({ row }) => (
                        <IndeterminateCheckbox
                            checked={row.getIsSelected()}
                            disabled={!row.getCanSelect()}
                            indeterminate={row.getIsSomeSelected()}
                            onChange={row.getToggleSelectedHandler()}
                            onCheckBoxChange={(e) =>
                                handleCheckBoxChange(
                                    e.target.checked,
                                    row.original
                                )
                            }
                        />
                    ),
                },
                ...columns
            ]
        }
        return columns
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnsProp, selectable])

    const table = useReactTable({
        data,
        columns: hasOldColumnMetaKey ? [] : finalColumns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        manualPagination: true,
        manualSorting: true,
        onSortingChange: setSorting,
        state: {
            sorting,
        },
    })


    const exportExcel = async (rows) => {
       try {
        const csvConfig = mkConfig({
            ...CSV_CONFIG_DEFAULTS,
            filename: `${formatDate(new Date())}`,
        })

        if (getCSVRawData) {
            const rowData = await getCSVRawData(rows)

            console.log('rowData', rowData);
            
            const csv = generateCsv(csvConfig)(rowData)
            download(csvConfig)(csv)
        }
       } catch (error) {
              console.log('Error exporting excel', error);
       }
    }

    const resetSorting = () => {
        table.resetSorting()
    }

    const resetSelected = () => {
        table.toggleAllRowsSelected(false)
    }

    useImperativeHandle(ref, () => ({
        resetSorting,
        resetSelected
    }))

    if (hasOldColumnMetaKey) {

        const message = 'You are using old react-table v7 column config, please use v8 column config instead, refer to our demo or https://tanstack.com/table/v8'

        if (process.env.NODE_ENV === 'development') {
            console.warn(message)
        }

        return (
            <Alert>{message}</Alert>
        )
    }

    return (
        <Loading loading={loading && data.length !== 0} type="cover">
            <Table className="dark:bg-custom-900">
                <THead>
                    {table.getHeaderGroups().map((headerGroup) => {
                        return (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header, idx) => {
                                    return (
                                        <Th
                                            className={`${idx === 0 ? 'rounded-tl-full rounded-bl-full' : ''} ${idx === headerGroup.headers.length - 1 ? 'rounded-tr-full rounded-br-full' : ''}`}
                                            key={header.id}
                                            colSpan={header.colSpan}
                                        >
                                            {header.isPlaceholder ? null : (
                                                <div
                                                    className={
                                                        classNames(
                                                            header.column.getCanSort() && 'cursor-pointer select-none point',
                                                            loading && 'pointer-events-none'
                                                        )
                                                    }
                                                    onClick={header.column.getToggleSortingHandler()}
                                                >
                                                    {flexRender(
                                                        header.column.columnDef
                                                            .header,
                                                        header.getContext()
                                                    )}
                                                    {/* {header.column.getCanSort() && <Sorter sort={header.column.getIsSorted()} />} */}
                                                </div>
                                            )}
                                        </Th>
                                    )
                                })}
                            </Tr>
                        )
                    })}
                </THead>

                {!loading && data.length === 0 && (
                    <TBody>
                        <Tr>
                            <Td colSpan={finalColumns.length} className="text-center">
                                <div className="flex items-center justify-center my-auto h-full">
                                    <div className="flex flex-col items-center justify-center text-center">
                                        <HiOutlineClipboardList className="text-4xl" />
                                        <h4 className="mt-4">
                                            No Reports Found
                                        </h4>
                                    </div>
                                </div>
                            </Td>
                        </Tr>
                    </TBody>
                )}

                {loading && data.length === 0 ? (
                    <TableRowSkeleton
                        columns={finalColumns.length}
                        rows={pagingData.pageSize}
                        avatarInColumns={skeletonAvatarColumns}
                        avatarProps={skeletonAvatarProps}
                    />
                ) : (
                    <TBody>
                        {table
                            .getRowModel()
                            .rows.slice(0, pageSize)
                            .map((row) => {
                                if ((data.length - 1) === row.index && highLightLastRow) {
                                    let iterIdx = 0

                                    return <>
                                        <tr className="bg-white h-16 font-medium">
                                            {row.getVisibleCells().map((cell) => {
                                                let roundedCls = ''
                                                if (iterIdx === 0) {
                                                    roundedCls = 'rounded-bl-md'
                                                }

                                                if (iterIdx === row.getVisibleCells().length - 1) {
                                                    roundedCls = 'rounded-br-md'
                                                }

                                                iterIdx++

                                                return (
                                                    <Td className={`text-black text-left ${roundedCls}`} key={cell.id}>
                                                        <span className="text-black">
                                                            {flexRender(
                                                                cell.column.columnDef.cell,
                                                                cell.getContext()
                                                            )}
                                                        </span>
                                                    </Td>
                                                )
                                            })}
                                        </tr>
                                    </>
                                }

                                return (
                                    <Tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => {
                                            return (
                                                <Td key={cell.id}>
                                                    {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext()
                                                    )}
                                                </Td>
                                            )
                                        })}
                                    </Tr>
                                )
                            })}
                    </TBody>
                )}
            </Table>

            {paginate && (
                <div className="flex items-center justify-between mt-4">
                    {showPagination && (
                        <Pagination
                            pageSize={pageSize}
                            currentPage={pageIndex}
                            total={total}
                            onChange={handlePaginationChange}
                        />
                    )}

                    <div className='flex items-center gap-4'>
                        {getCSVRawData && (
                            <button
                                className='rounded-full bg-custom-100 w-9 h-9 flex items-center justify-center'
                                onClick={() => exportExcel(table.getRowModel().rows)}
                                title='Export to Excel'
                            >
                                <BiDownload
                                    className="text-lg text-white"
                                />
                            </button>
                        )}
                        {showPagination && (
                            <div style={{ minWidth: 130 }} className="rounded-full">
                                <Select
                                    size="sm"
                                    menuPlacement="top"
                                    isSearchable={false}
                                    value={pageSizeOption.filter((option) => {
                                        if (highLightLastRow) {
                                            return option.value === pageSize - 1
                                        }

                                        return option.value === pageSize
                                    })}
                                    options={pageSizeOption}
                                    onChange={(option) => handleSelectChange(option.value)}
                                />
                            </div>
                        )}

                        {renderFooterActions && renderFooterActions()}
                    </div>
                </div>
            )}
            <div className="mt-4">
                {renderFooterText && renderFooterText()}
            </div>
        </Loading>
    )
})

DataTable.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    loading: PropTypes.bool,
    onCheckBoxChange: PropTypes.func,
    onIndeterminateCheckBoxChange: PropTypes.func,
    onPaginationChange: PropTypes.func,
    onSelectChange: PropTypes.func,
    onSort: PropTypes.func,
    pageSizes: PropTypes.arrayOf(PropTypes.number),
    selectable: PropTypes.bool,
    skeletonAvatarColumns: PropTypes.arrayOf(PropTypes.number),
    skeletonAvatarProps: PropTypes.object,
    pagingData: PropTypes.shape({
        total: PropTypes.number,
        pageIndex: PropTypes.number,
        pageSize: PropTypes.number,
    }),
    paginate: PropTypes.bool,
    highLightLastRow: PropTypes.bool,
    getCSVRawData: PropTypes.func,
    renderFooterActions: PropTypes.func,
    showPagination: PropTypes.bool,
    renderFooterText: PropTypes.func,
}

DataTable.defaultProps = {
    pageSizes: [5, 10, 25],
    pagingData: {
        total: 0,
        pageIndex: 1,
        pageSize: 10,
    },
    data: [],
    columns: [],
    selectable: false,
    loading: false,
    paginate: true,
    highLightLastRow: false,
    showPagination: true,
}

export default DataTable
